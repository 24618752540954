@import 'normalize';
@import 'variables';
@import 'mixins';
@import 'preloader';
* {
   box-sizing: border-box;
}

html {
   font-size: 10px;
}
.body {
   @include font(1.8rem, $lh: 1.1, $ls: 0.1rem);
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   background-color: $body-bg;
   scroll-behavior: smooth;
   overflow-x: hidden;
   position: relative;
   width: 100vw;
   height: 100vh;
}

.container {
   margin: 0 auto;
   max-width: 1470px;
   padding: 0 15px;
}

.section {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   z-index: -1;
   background-size: 100% 100%;
   background-repeat: no-repeat;
   background-position: 50% bottom;
   overflow: hidden;
   display: none;

   &.section_active {
      z-index: 10;
      display: block;
   }

}

.fasad {
   background-image: url(../img/fasad.jpg);

   .dot {
      position: absolute;
      bottom: 8%;
      left: 50%;
      transform: translateX(-50%);
   }

   &__icon {
      position: absolute;
      top: 30px;
      left: 30px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transform: translateY(0%) scale(1);

      @include tr();

      svg {
         width: 12px;
         height: 28px;
         fill: #fff;
      }

      &:after {
         content: "";
         width: 50px;
         height: 50px;
         border-radius: 50%;
         background-color: rgba(0, 0, 0, 0.3);
         position: absolute;
         left: 0;
         top: 0;
         animation: pulse_sm 1.5s linear infinite forwards;
      }

      &_hidden {
         opacity: 0;
         transform: translateY(-50%) scale(0);

         @include tr();
      }
   }

   &__tip {
      width: 90%;
      margin: 20px auto;
      max-width: 540px;
      padding: 15px;
      border-radius: 12px;
      overflow: hidden;
      background: rgba(0, 0, 0, 0.3);
      @include font(1.2rem, 500, 1.4, 0.001em, #fff);
      box-shadow: 0px 6px 11px rgba(0, 0, 0, 0.25);
      opacity: 0;
      transform: scale(0);
      transform-origin: top left;

      &:after {
         content: "";
         position: absolute;
         top: 0;
         right: -100px;
         bottom: 0;
         left: 0;
      }

      @include tr();

      &_shown {
         opacity: 1;
         transform: scale(1);

         @include tr();
      }
   }
}
.candidates {
   background-image: url(../img/candidates.jpg);
}
.partners {
   background-image: url(../img/partners.jpg);
}
.liaders {
   background-image: url(../img/liaders.jpg);
}

.btns__back {
   position: fixed;
   right: 30px;
   top: 30px;

   .nav {
      display: inline-flex;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      background-color: rgba(6, 6, 6, .75);
      box-shadow: 0px 9px 6px rgba(0, 0, 0, 0.25);
      
      img {
         width: 20px;
         height: 20px;
      }

      @include hover {
         transform: scale(1.1);
         box-shadow: 3px 12px 6px rgba(0, 0, 0, 0.4);
      }
   }
}

.dot {
   position: absolute;
   transform: translate(-50%, -50%);
   text-align: center;
   text-decoration: none;
   @include font(1.6rem, 600, 1.5, 0, #fff);

   &,
   &:before {
      display: inline-block;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: rgba(238, 22, 22, 0.6);
   }

   &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      animation: pulse 1.5s linear infinite forwards;
   }

   &__partners {
      left: 16%;
      top: 59%;
   }
   &__liaders {
      right: 15%;
      top: 59%;
   }
   &__candidates {
      left: 50%;
      top: 66%;
   }
}

.modal {
   opacity: 0;
   overflow: hidden;

   @include tr();

   &.modal_open {
      opacity: 1;

      @include tr(0.5s);

      .modal__content {
         transform: translate(-50%, 2%);

         @include tr();
      }
   }

   position: absolute;
   top: 0;
   bottom: 0;
   right: 0;
   left: 0;
   background-color: rgba(0, 0, 0, 0.7);
   filter: blur(0px);

   &__content {
      position: absolute;
      top: 0%;
      left: 50%;
      transform: translate(-50%, 0);
      border-radius: 14px;
      overflow: hidden;
      box-shadow: 0 23px 40px rgba(0, 0, 0, 0.2);
      width: 65%;
      max-width: 940px;

      @include tr();

      img {
         width: 100%;
      }
   }
   &__head {
      height: 55px;
      position: relative;
      background-image: linear-gradient(rgb(96, 108, 138), rgba(174, 192, 236, 1));
      border-radius: 6px;
      margin-bottom: -6px;
      display: flex;
      align-items: center;
      justify-content: center;
   }
   &__title {
      text-transform: uppercase;
      @include font(1.2rem, 800, $fc: #fff);

   }
   &__close {
      position: absolute;
      right: 2%;
      top: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transform: translateY(-50%);
      color: #fff;
      font-size: 2rem;
   }
}

.btn {
   @include font(1.4rem, 600, 1.2, $fc: #fff);
   text-decoration: none;
   background: linear-gradient(90deg, #4B7DFF 0%, #2D5CD8 50%, #4B7DFF 100%);
   background-size: 200%;
   background-position: 100%;
   box-shadow: 0px 16px 30px rgba(0, 0, 0, 0.4);
   border-radius: 6px;
   padding: 3px;
   padding-left: 10px;
   width: 160px;
   display: flex;
   justify-content: space-between;
   align-items: center;

   @include posA(43%);

   .icon {
      width: 20px;
      height: 20px;
      border-radius: 6px;
      background-color: rgba(255, 255, 255, 0.15);
      display: flex;
      justify-content: center;
      align-items: center
   }

   @include hover {
      background-position: 0%;
   }
}

.robot {
   position: absolute;
   bottom: 0;
   left: 50%;
   height: 50px;
   width: 30px;

   img {
      width: 100%;
      height: 100%;
   }

   &__icon {
      position: absolute;
      right: -145%;
      top: -55%;
      width: 52px;
      height: 52px;
      background-color: #3065F2;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      z-index: 15;
      cursor: pointer;
      opacity: 1;

      @include tr();
      
      svg {
         width: 12px;
         height: 28px;
         fill: #fff;
      }

      &:before {
         content: "";
         position: absolute;
         left: -15px;
         top: 65%;
         border-top: 15px solid #3065F2;
         border-left: 20px solid transparent;
         border-right: 20px solid transparent;
         transform: rotateZ(-55deg);
      }

      &:after {
         content: "";
         width: 52px;
         height: 52px;
         border-radius: 50%;
         background-color: #3065F2;
         position: absolute;
         left: 0;
         top: 0;
         z-index: -1;
         animation: pulse_sm 1.5s linear infinite forwards;
      }

      &_hidden {
         opacity: 0;
         transform: translateY(50%);

         @include tr();
      }
   }
   &__tip {
      opacity: 0;
      position: absolute;
      left: 30%;
      bottom: -50%;
      height: 0;
      z-index: 0;
      transform: translate(-50%, -50%);
      width: 290px;
      background-color: #fff;
      border-radius: 12px;
      box-shadow: 0px 8px 22px rgba(0, 0, 0, 0.35);
      padding: 12px;
      @include font(1rem, 500, 1.6, 0.001em , #1e1e1e);

      @include tr();

      &_shown {
         opacity: 1;
         transform: translate(-50%, -70%);

         @include tr();
      }
   }
   &__close {
      position: absolute;
      top: 12px;
      right: 12px;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.8rem;
      cursor: pointer;
   }
}

@media screen and (min-width: 576px) {
}

@media screen and (min-width: 768px) {
   .fasad {
      &__tip {
         position: absolute;
         top: 10px;
         left: 10px;
         top: 30px;
         left: 30px;
         font-size: 1.4rem;
      }
   }
   .modal {
      &__title {
         font-size: 2rem;
      }
      &__close {
         font-size: 4rem;
      }
   }
}

@media screen and (max-height: 375px) {
   .robot {
      height: 70px;
      width: 40px;
   }
}
@media screen and (max-height: 430px) {
   .robot {
      height: 80px;
      width: 45px;
   }
}

@media screen and (min-width: 992px) {
   .fasad {
      &__tip {
         font-size: 1.6rem;
         padding: 30px;
         opacity: 1;
         transform: scale(1);
      }
      &__icon {
         display: none;
      }
   }
   .robot {
      left: 50%;
      bottom: 2%;
      height: 100px;
      width: 50px;

      &__tip {
         width: 380px;
         left: auto;
         bottom: auto;
         right: 0;
         top: 0;
         transform: translate(100%, -50%);
         font-size: 1.2rem;
         
         &:before {
            content: "";
            position: absolute;
            left: -15px;
            top: 65%;
            border-top: 15px solid #fff;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            transform: rotateZ(-55deg);
         }

         &_shown {
            transform: translate(100%, -70%);
         }
      }
      &__icon {
         right: -130%;
         top: -50%;
      }
   }
   .btns__back {
      right: 36px;
      top: 50px;
   
      .nav {
         display: inline-flex;
         width: 64px;
         height: 64px;
         
         img {
            width: 30px;
            height: 30px;
         }
      }
   }
   .modal {
      &__content {
         width: 90%;
         max-width: 700px;
      }
      &.modal_open {
         .modal__content {
            top: 50%;
            transform: translate(-50%, -50%);
            max-height: 95%;
            overflow: hidden;
         }
      }
   }
   .btn {
      padding: 5px 5px 5px 24px;
      width: 215px;

      .icon {
         width: 40px;
         height: 40px;
      }
   }
}
   
@media screen and (min-width: 1440px) {
   
   .robot {
      height: 165px;
      width: 100px;

      &__icon {
         right: -70%;
         top: -20%;
      }
   }
}
@media screen and (min-width: 1600px) {
   .modal {
      &__content {
         max-width: 940px;
      }
      &__head {
         height: 88px;
      }
      &__title {
         font-size: 3.4rem;
      }
   }
}
   
@media screen and (orientation: portrait) and (max-width: 576px) {
   .body {
      > div {
         width: 100vh;
         height: 100vw;
         position: absolute;
         bottom: 100%;
         transform: rotate(90deg);
         transform-origin: 0 100%;
      }
   }
   .modal {
      &.modal_open {
         .modal__content {
            transform: translate(-50%, 5%);
         }
      }
   }
   // .btns__back {
   //    top: 52%;
   // }
}
@media screen and (max-width: 992px) and (orientation: landscape) {
   .body {
      > div {
         width: 100vw;
         height: 100vh;
      }
   }
   
   .robot {
      height: 80px;
      width: 45px;
   }
}
@media screen and (max-width: 992px) and (orientation: portrait) {
   .robot {
      height: 80px;
      width: 45px;
   }
}
@media screen and (max-width: 992px) and (max-height: 430px) {
   .modal {
      &__content {
         max-width: 460px;
      }
   }
}

@import 'animations';