@font-face {
   font-family: Montserrat;
   font-style: normal;
   font-weight: 400;
   src: url("../fonts/montserrat/Montserrat-Regular.ttf") format("opentype");
}
@font-face {
   font-family: Montserrat;
   font-style: normal;
   font-weight: 500;
   src: url("../fonts/montserrat/Montserrat-Medium.ttf") format("opentype");
}
@font-face {
   font-family: Montserrat;
   font-style: normal;
   font-weight: 600;
   src: url("../fonts/montserrat/Montserrat-SemiBold.ttf") format("opentype");
}
@font-face {
   font-family: Montserrat;
   font-style: normal;
   font-weight: 700;
   src: url("../fonts/montserrat/Montserrat-Bold.ttf") format("opentype");
}
@font-face {
   font-family: Montserrat;
   font-style: normal;
   font-weight: 800;
   src: url("../fonts/montserrat/Montserrat-ExtraBold.ttf") format("opentype");
}

$prime-ff: 'Montserrat', sans-serif;

$body-color: #000;
$primary-color: #3065F2;
$secondary-color: #FC9426;

$dark-gray: #666666;
$medium-gray: #898f8f;
$light-gray: #cccccc;

$border-width: 2px;

$body-bg: #fff;

$btn-gradient: linear-gradient(90deg, #2D5CD8 0%, #4B7DFF 100%);
$btn-gradient-hover: linear-gradient(90deg, #2D5CD8 80%, #4B7DFF 100%);
$btn-border-radius: 10px;