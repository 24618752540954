@keyframes pulse {
   from {
      transform: scale(1);
   }
   to {
      transform: scale(3);
      opacity: 0;
   }
}
@keyframes pulse_sm {
   from {
      transform: scale(1);
   }
   to {
      transform: scale(1.5);
      opacity: 0;
   }
}
@keyframes rotate {
   100% {
      transform: rotateZ(-360deg);
   }
}
@keyframes rotate-wrapper {
   100% {
      transform: rotateZ(360deg);
   }
}
@keyframes hiding {
   100% {
      opacity: 0;
      transform: translateY(-20%);
   }
}