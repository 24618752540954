@mixin font($fz: inherit, $fw: 400, $lh: 1.6, $ls: 0.03em, $fc: $body-color, $ff: $prime-ff) {
    font-family: $ff;
    font-size: $fz;
    color: $fc;
    font-weight: $fw;
    line-height: $lh;
    letter-spacing: $ls;
}

@mixin posA($top: 50%, $left: 50%, $delta_x: -50%, $delta_y: -50%, $bottom: auto, $right: auto) {
    position: absolute;
    top: $top;
    left: $left;
    bottom: $bottom;
    right: $right;
    transform: translate($delta_x, $delta_y);
}

@mixin tr($t: 0.3s) {
    transition: all $t ease-in;
}

@mixin hover {
    @include tr;
    &:hover,
    &:focus,
    &:active {
        @content
    }
}