.preloader {
   position: absolute;
   top: 0;
   left: 0;
   bottom: 0;
   right: 0;
   z-index: 100;

   background-color: #fff;
   display: flex;
   justify-content: center;
   align-items: center;
   overflow: hidden;

   &_hidden {
      animation: hiding 1.3s linear 1 forwards;
      // @include tr(1s);
   }

   &__carousel {
      position: relative;
      width: 100%;
      height: 100vh; 
   }

   .carousel {

      &__center {
         @include posA();
         
         img {
            max-width: 260px;
         }
      }

      &__wrapper {
         @include posA();
      }

      &__circles {
         margin: 0 auto;
         width: 540px;
         height: 540px;
         background-image: url(../img/preloader_circles.png);
         background-size: cover;
         background-repeat: no-repeat;
         background-position: 50% 50%;
         animation: rotate-wrapper 60s linear infinite;

         img {
            border: 2px solid #fff;
            border-radius: 50%;
            position: absolute;
            animation: rotate 60s linear infinite;
         }

         img:nth-child(1) {
            width: 60px;
            height: 60px;
            box-shadow: 0px 13px 24px rgba(169, 158, 146, 0.5);
            bottom: 13%;
            left: 40%;
         }
         img:nth-child(2) {
            width: 40px;
            height: 40px;
            box-shadow: 0px 13px 24px rgba(239, 137, 155, 0.5);
            bottom: -2%;
            left: 50%;
         }
         img:nth-child(3) {
            width: 30px;
            height: 30px;
            box-shadow: 0px 13px 24px rgba(255, 209, 80, 0.5);
            top: -1%;
            left: 35%;
         }
         img:nth-child(4) {
            width: 40px;
            height: 40px;
            box-shadow: 0px 13px 24px rgba(190, 131, 230, 0.5);
            bottom: 32%;
            right: 17%;
         }
         img:nth-child(5) {
            width: 60px;
            height: 60px;
            box-shadow: 0px 13px 24px rgba(75, 164, 193, 0.5);
            bottom: 3%;
            right: 16%;
         }
         img:nth-child(6) {
            width: 30px;
            height: 30px;
            box-shadow: 0px 13px 24px rgba(111, 167, 188, 0.5);
            top: 15%;
            right: 45%;
         }
         img:nth-child(7) {
            width: 50px;
            height: 50px;
            box-shadow: 0px 13px 24px rgba(169, 158, 146, 0.5);
            top: 29%;
            left: 20%;
         }
         img:nth-child(8) {
            width: 50px;
            height: 50px;
            box-shadow: 0px 13px 24px rgba(100, 170, 30, 0.3);
            top: -3%;
            right: 35%;
         }
      }
   }
}
@media screen and (min-width: 576px) {
   .preloader {
      .carousel {
         &__circles {
            img:nth-child(1) {
               bottom: 25%;
               left: 19.5%;
            }
            img:nth-child(2) {
               bottom: 33%;
               left: -1%;
            }
            img:nth-child(3) {
               top: 37%;
               left: -1%;
            }
            img:nth-child(4) {
               bottom: 27%;
               right: 21%;
            }
            img:nth-child(5) {
               bottom: 38%;
               right: -4%;
            }
            img:nth-child(6) {
               top: 36%;
               right: 16.5%;
            }
            img:nth-child(7) {
               top: 29%;
               left: 20%;
            }
            img:nth-child(8) {
               top: 28.5%;
               right: 1%;
            }
         }
      }
   }
}
@media screen and (min-width: 768px) {
   .preloader {
      .carousel {
         &__circles {
            width: 660px;
            height: 660px;
         }
         &__center {
            
            img {
               max-width: 400px;
            }
         }
      }
   }
}
@media screen and (min-width: 992px) {
   .preloader {
      .carousel {
         &__circles {

            img:nth-child(1) {
               bottom: 25%;
               left: 19.5%;
            }
            img:nth-child(2) {
               bottom: 33%;
               left: -1%;
            }
            img:nth-child(3) {
               top: 37%;
               left: -1%;
            }
            img:nth-child(4) {
               bottom: 27%;
               right: 21%;
            }
            img:nth-child(5) {
               bottom: 38%;
               right: -4%;
            }
            img:nth-child(6) {
               top: 36%;
               right: 16.5%;
            }
            img:nth-child(7) {
               top: 29%;
               left: 20%;
            }
            img:nth-child(8) {
               top: 28.5%;
               right: 1%;
            }
         }
      }
   }
}
@media screen and (min-width: 1200px) {
   .preloader {
      .carousel {
         &__circles {
            width: 920px;
            height: 920px;

            img {
               border-width: 4px;
            }
            img:nth-child(1) {
               width: 100px;
               height: 100px;
               bottom: 25%;
               left: 19.5%;
            }
            img:nth-child(2) {
               width: 80px;
               height: 80px;
               bottom: 33%;
               left: -2%;
            }
            img:nth-child(3) {
               width: 60px;
               height: 60px;
               top: 37%;
               left: -2%;
            }
            img:nth-child(4) {
               width: 80px;
               height: 80px;
               bottom: 27%;
               right: 19%;
            }
            img:nth-child(5) {
               width: 100px;
               height: 100px;
               bottom: 38%;
               right: -5%;
            }
            img:nth-child(6) {
               width: 60px;
               height: 60px;
               top: 36%;
               right: 16.5%;
            }
            img:nth-child(7) {
               width: 90px;
               height: 90px;
               top: 29%;
               left: 17%;
            }
            img:nth-child(8) {
               width: 90px;
               height: 90px;
               top: 28.5%;
               right: -3%;
            }
         }
         &__center {
            img {
               max-width: 550px;
            }
         }
      }
   }
}
@media screen and (min-width: 1440px) {
   .preloader {
      .carousel {
         &__circles {
            width: 1200px;
            height: 1200px;

            img {
               border-width: 4px;
            }
            img:nth-child(1) {
               width: 160px;
               height: 160px;
               bottom: 25%;
               left: 17%;
            }
            img:nth-child(2) {
               width: 110px;
               height: 110px;
               bottom: 33%;
               left: -3%;
            }
            img:nth-child(3) {
               width: 88px;
               height: 88px;
               top: 37%;
               left: -2%;
            }
            img:nth-child(4) {
               width: 110px;
               height: 110px;
               bottom: 27%;
               right: 18%;
            }
            img:nth-child(5) {
               width: 160px;
               height: 160px;
               bottom: 38%;
               right: -6%;
            }
            img:nth-child(6) {
               width: 88px;
               height: 88px;
               top: 36%;
               right: 15%;
            }
            img:nth-child(7) {
               width: 110px;
               height: 110px;
               top: 29%;
               left: 18%;
            }
            img:nth-child(8) {
               width: 110px;
               height: 110px;
               top: 28.5%;
               right: -2%;
            }
         }
      }
   }
}
@media screen and (min-width: 1600px) {
   .preloader {
      .carousel {
         &__center {            
            img {
               max-width: 890px;
            }
         }
         &__circles {
            width: 1785px;
            height: 1785px;

            img:nth-child(1) {
               bottom: 25%;
               left: 19.5%;
            }
            img:nth-child(2) {
               bottom: 33%;
               left: -1%;
            }
            img:nth-child(3) {
               top: 37%;
               left: -1%;
            }
            img:nth-child(4) {
               bottom: 27%;
               right: 21%;
            }
            img:nth-child(5) {
               bottom: 38%;
               right: -4%;
            }
            img:nth-child(6) {
               top: 36%;
               right: 16.5%;
            }
            img:nth-child(7) {
               top: 29%;
               left: 20%;
            }
            img:nth-child(8) {
               top: 28.5%;
               right: 1%;
            }
         }
      }
   }
}